.node-architecture-parent .node-architecture-header {
  flex-shrink: 0;
  align-items: center;
  padding: 15px 10px 0px 20px;
}
.node-architecture-parent md-list-item.md-2-line,
.node-architecture-parent md-list-item.md-2-line > .md-no-style {
  min-height: 32px !important;
  height: 32px !important;
}
.node-architecture-parent .node-architecture-list md-checkbox {
  margin-right: 16px !important;
}
.node-architecture-parent .node-architecture-list .analysis-button {
  width: 15px !important;
  height: 15px !important;
  min-height: 0 !important;
  min-width: 0 !important;
  border-radius: 0 !important;
  margin: 16px 2px !important;
}
.node-architecture-parent .node-architecture-list .node-architecture {
  border: 2px solid #4FD37E !important;
  color: #F6F6F9 !important;
  background-color: #4FD37E !important;
}
.node-architecture-parent .node-architecture-list .bat-architecture-list-counter {
  font-size: 15px;
  color: #47506e;
}
.node-architecture-parent .node-architecture-list .bat-architecture-list-search {
  font-weight: 300;
}
.node-architecture-parent .node-architecture-list .md-input-has-placeholder {
  padding-bottom: 0 !important;
}
.node-architecture-parent .node-architecture-list input:focus {
  outline: 0;
}
.node-architecture-parent .node-architecture-list .md-subheader-content .add-button {
  position: relative;
  color: inherit;
  display: inline-block;
  text-align: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 22px;
  cursor: pointer;
  transition: 0.2s all;
  margin-top: -8px;
  margin-bottom: -10px;
  font-size: 16px;
  display: flex;
}
.node-architecture-parent .node-architecture-list .md-subheader-content .add-button:hover {
  color: #000;
}
.node-architecture-parent .node-architecture-list .md-subheader-content .add-button:before {
  transform: scale3d(0, 0, 0);
  transition: 0.2s all;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 0;
}
.node-architecture-parent .node-architecture-list .md-subheader-content .add-button:hover:before {
  transform: scale3d(1, 1, 1);
}
.node-architecture-parent .node-architecture-list .md-subheader-content .add-button:active,
.node-architecture-parent .node-architecture-list .md-subheader-content .add-button:focus {
  outline: none;
}
.node-architecture-parent .node-architecture-list .md-subheader-content .add-button .mdi {
  display: flex;
  justify-content: center;
  align-items: center;
}
.node-architecture-parent .node-architecture-list md-list {
  padding: 0 0 0 0 !important;
}
.node-architecture-parent .node-architecture-list md-list-item,
.node-architecture-parent .node-architecture-list md-list-item .md-list-item-inner {
  min-height: 20px;
  height: 25px;
}
.node-architecture-parent .node-architecture-list .md-avatar {
  width: 18px !important;
  height: 18px !important;
  margin-top: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 700;
  border-radius: 0 !important;
  line-height: 15px;
}
.node-architecture-parent .node-architecture-list .md-avatar.personchip {
  border-color: #E91E63;
  color: #E91E63;
}
.node-architecture-parent .node-architecture-list .md-2-line {
  min-height: 25px !important;
}
.node-architecture-parent .node-architecture-list md-list-item.md-2-line,
.node-architecture-parent .node-architecture-list md-list-item.md-2-line > .md-no-style {
  min-height: 25px !important;
}
.node-architecture-parent .node-architecture-list .md-list-item-text h3 {
  white-space: inherit !important;
}
.node-architecture-parent .node-architecture-list .bat-listitem-stat {
  display: flex;
  padding: 15px;
  align-items: center;
  align-content: center;
}
.node-architecture-parent .node-architecture-list .md-secondary .mdi:focus {
  outline: none;
}
.node-architecture-parent .circle-button {
  position: relative;
  color: inherit;
  display: inline-block;
  text-align: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 22px;
  cursor: pointer;
  transition: 0.2s all;
}
.node-architecture-parent .circle-button:hover {
  color: #000;
}
.node-architecture-parent .circle-button:before {
  transform: scale3d(0, 0, 0);
  transition: 0.2s all;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 0;
}
.node-architecture-parent .circle-button:hover:before {
  transform: scale3d(1, 1, 1);
}
.node-architecture-parent .circle-button:active,
.node-architecture-parent .circle-button:focus {
  outline: none;
}
.node-architecture-parent .node-indent {
  margin-left: 30px;
}
.node-architecture-parent .inline {
  display: inline-block;
}
.node-architecture-parent .mdicon-Size {
  font-size: 15px;
}
.node-architecture-parent .submenu-position {
  fill: #808080;
  font-size: 18px;
}
.node-architecture-parent .md-button {
  height: 50px;
  margin-top: 15px;
}
.node-architecture-parent .menu-icon-position {
  margin-top: 20px;
}
.node-architecture-parent .menu-button-position {
  min-width: 75px;
}
.node-architecture-parent .menu-subsection {
  font-size: 25px;
}
